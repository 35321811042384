import { Component, EventEmitter, HostListener, Input, OnInit, Output, ViewChild } from '@angular/core';
import { GetRowIdFunc, GetRowIdParams, GridApi, GridReadyEvent, IServerSideDatasource, IsRowSelectable, SelectionChangedEvent } from 'ag-grid-community';
import { BehaviorSubject } from 'rxjs';
import { AGGridActionsCustomColumnComponent } from '@components/ag-grid-actions-custom-column/ag-grid-actions-custom-column.component';
import { AGGridActionsWithSubmenuColumnComponent } from '@components/ag-grid-actions-custom-column/ag-grid-actions-with-submenu-column.component';
import { AgGridCustomAutocompleteFilterComponent } from '@components/ag-grid-custom-autocomplete-filter/ag-grid-custom-autocomplete-filter.component';
import { AgGridCustomAutocompleteFloatingFilterComponent } from '@components/ag-grid-custom-autocomplete-floating-filter/ag-grid-custom-autocomplete-floating-filter.component';
import { AgGridCustomListFilterComponent } from '@components/ag-grid-custom-list-filter/ag-grid-custom-list-filter.component';
import { AgGridCustomListFloatingFilter } from '@components/ag-grid-custom-list-floating-filter/ag-grid-custom-list-floating-filter.component';
import { AgGridCustomTimeFilterComponent } from '@components/ag-grid-custom-time-filter/ag-grid-custom-time-filter.component';
import { AGGridCustomToolPanelComponent } from '@components/ag-grid-custom-toolpanel/ag-grid-custom-toolpanel.component';
import { AGGridDownloadCustomColumnComponent } from '@components/ag-grid-download-custom-column/ag-grid-download-custom-column.component';
import { AgGridMaskedValueCellComponent } from '@components/ag-grid-masked-value-cell/ag-grid-masked-value-cell.component';
import { AGGridQuickActionsComponent } from '@components/ag-grid-quick-actions/ag-grid-quick-actions.component';
import { AgGridRecurringAuditCellRendererComponent } from '@components/ag-grid-recurring-audit-cell-renderer/ag-grid-recurring-audit-cell-renderer.component';
import { AgGridSdsStatusTooltipComponent } from '@components/ag-grid-sds-status-tooltip/ag-grid-sds-status-tooltip.component';
import { AGGridSettingsToolPanelComponent } from '@components/ag-grid-settings-toolpanel/ag-grid-settings-toolpanel.component';
import { ApiEntityTypesEnum } from '@constants/enums/entity-types.enum';
import { GridMode } from '@constants/enums/grid-mode.enum';
import { environment } from '@env/environment';
import { AgGridStateModel } from '@models/aggrid-state.model';
import { GridView } from '@models/entity-models/autogenerated/gridview';
import { SDSSearchRadioButtonComponent } from '@pages/chemicals/sds-search/sds-search-radio-button.component';
import { AgGridStateService } from '@services/aggridState.service';
import { ApiFactory } from '@services/core/api-factory.class';
import { applyGridViewToGrid, applyPredefinedGridState, defaultGridInit, formatColumnsForExportAndClipboard, getDefaultColDef } from '@utilities/grid-helpers';
import { isNullOrUndefined } from '@utilities/helpers';

@Component({
    selector: 'ag-gridV2',
    templateUrl: './ag-gridv2.component.html',
    styleUrls: ['./ag-gridv2.component.scss'],
})
export class AGGridV2Component implements OnInit {

    // Variables
    context: any;
    loading = true;
    displayedRowCount = 0;
    selectedRowsCount = 0
    toolbarActionsEnabled = false;
    gridColumnsChanged = false;
    isGridReady = false;
    isOnGridReadyDone = false;
    sideBar: any;
    exportAll = false;
    floatingFilterVisible = false;
    groupingEnabled = false;
    getDefaultViewPromise: Promise<GridView>;
    defaultView: GridView;
    isSaveStateAllowed = true;
    quickActions: string[] = [];
    quickActionsValues: string[] = [];
    quickActionsColumnFilter: string[];
    quickActionsEntity: string;
    marginBottom = '60px';

    defaultTextFilterOptions = ['equals','notEqual','contains','notContains','startsWith','endsWith'];
    defaultNumberFilterOptions = ['equals','notEqual','lessThan','lessThanOrEqual','greaterThan','greaterThanOrEqual','inRange'];
    defaultDateFilterOptions = ['equals','notEqual','lessThan','greaterThan','inRange'];

    public gridApi: GridApi;
    gridRowData: any[] = [];
    gridOptions: any;
    public icons: {
        [key: string]: Function | string;
      } = {
        // use font awesome for menu icons
        customGear: '<span class="ag-icon fa fa-gear" style="margin-right: 0px;" />',
      };

    columnsDateIds: string[] = [];
    columnsDateWidth = 221;
    @ViewChild('agGrid', { static: false })
    private agGrid: AGGridV2Component;

    private _rowData: any[];
    @Input()
    set rowData(tableData: any[]) {
        this._rowData = tableData;
        this.loadData();
    }
    get rowData() { return this._rowData; }
    @Input()actionCellRenderer = 'agGridActionsCustomColumnComponent';
    private _actionsChildComponent = '';
    public get actionsChildComponent() {
        return this._actionsChildComponent;
    }
    public set actionsChildComponent(value) {
        if (!isNullOrUndefined(this.actionsChildComponent)) {
            this.handleGridActionComponentEvent(value);
            this._actionsChildComponent = value;
        }
    }

    @Input() getRowId: GetRowIdFunc = (params: GetRowIdParams) => {
        return params.data.id;
    };

    public isRowSelectable: IsRowSelectable = function (rowNode) {
        return !rowNode.group;
      };

    @ViewChild('agGridQuickActions', { static: false })
    private quickActionsComponent: AGGridQuickActionsComponent;

    constructor(private agGridState: AgGridStateService) {
        this.context = { componentParent: this };
    }

    ngOnInit(): void {
        const rowGroupColumns = this.columnDefinition.filter(c => c.enableRowGroup);
        const groupFilterColumn = this.columnDefinition.filter(c => c.groupFilter).length ? this.columnDefinition.filter(c => c.groupFilter)[0].field : this.columnDefinition[0].field;
        if (rowGroupColumns.length) {
            if (this.gridMode === GridMode.ClientSideRowModel) {
                const autoGroupColumnDef = {
                    headerName: 'Group',
                    minWidth: 220,
                    valueGetter: function (params) {
                        if (params.node.group) {
                            return params.node.key;
                        } else {
                            return params.data[params.colDef.field];
                        }
                    },
                    headerCheckboxSelection: true,
                    headerCheckboxSelectionFilteredOnly: true,
                    cellRenderer: 'agGroupCellRenderer',
                    cellRendererParams: {
                        checkbox: true,
                    },
                    filter: 'agSetColumnFilter',
                    filterValueGetter: params => {
                        return params.data[groupFilterColumn];
                    },
                };
                this.gridOptions = { autoGroupColumnDef: autoGroupColumnDef, groupSelectsChildren: true, ...this.gridOptions };
            }
            this.gridOptions = { ...this.gridOptions, rowGroupPanelShow: 'always', suppressPropertyNamesCheck: true, suppressDragLeaveHidesColumns: true };
            this.groupingEnabled = true;
        }

        if (this.showQuickActions) {
            if (this.quickActionsField) {
                const quickActionsColumn = this.columnDefinition.find(c => c.field === this.quickActionsField && c.filter === 'agSetColumnFilter');
                if(!this.showQuickActionDefaultColumn) {
                    quickActionsColumn.hide = true;
                } else {
                    quickActionsColumn.hide = false;
                }
                this.quickActions = quickActionsColumn.filterParams.quickActionsDisplayValues ? quickActionsColumn.filterParams.quickActionsDisplayValues : quickActionsColumn.filterParams.values;
                this.quickActionsValues = quickActionsColumn.filterParams.values;
                this.quickActionsEntity = quickActionsColumn.filterParams.entity;
            }
            else {
                this.quickActions = ['Column definition not found'];
            }
        }
    }

    private _columnDefinition: any[];
    @Input()
    set columnDefinition(tableData: any[]) {
        this._columnDefinition = tableData;
        this.setDefaultFilterOptions();
    }
    get columnDefinition() { return this._columnDefinition; }

    private _actionMenuColumn: any[];
    @Input() set actionMenuColumn(value: any[]) {
        this._actionMenuColumn = value;
        if (this.isOnGridReadyDone) { // isOnGridReady ensures this only happens if action menu col changes after the grid has been initialized. Allowing us to change it after initialization.
            if (this.columnDefinition.findIndex(x => x.colId === 'ActionsColumn') > -1) {
                this.columnDefinition.splice(this.columnDefinition.findIndex(x => x.colId === 'ActionsColumn'), 1);
            }
            if ((this._actionMenuColumn?.length > 0)) {
                this.addActionMenuColumn(this.columnDefinition[0]?.colId === 'SelectColumn' ? 1 : 0);
                this.gridApi.setGridOption("columnDefs", this.columnDefinition);
            }
        }
    }
    get actionMenuColumn() { return this._actionMenuColumn; }

    @Input() gridStateModel: AgGridStateModel;
    @Input() defaultColumnDefinition: any = getDefaultColDef();
    @Input() showSideBar: boolean;
    @Input() noFiltersOnSideBar = false;
    @Input() hideSaveViews = false;
    @Input() rowSelection = 'multiple';
    @Input() rowHeight = 40;
    @Input() height = '100vh';
    @Input() maxHeight = '680px';
    @Input() overlayLoadingTemplate = '<span class="ag-overlay-loading-center">Fetching data...</span>';
    @Input() overlayNoRowsTemplate = '<span class="ag-overlay-loading-center">Your search did not return any results...</span>';
    @Input() showSelectColumn = false;
    @Input() overwriteSidebar: any;
    @Input() customToolPanelParams: any;
    @Input() customFilterList: string[] = [];
    @Input() autosizeColumns = true;
    @Input() forceClearRowData = false;
    @Input() gridMode: GridMode;
    @Input() dataSource: IServerSideDatasource;
    @Input() pageSize = environment.gridPaginationSize;
    @Input() customContextMenuItems: any[];
    @Input() enableCharts = false;
    @Input() additionalCustomSidePanelActions: any[];
    @Input() showPagination = true;
    @Input() initialLoadStateQuery: any = null;
    @Input() initialLoadStateQueryParams: any;
    @Input() hideSettingsToolPanel = false;
    @Input() gridId: any;
    @Input() showQuickActions = false;
    @Input() quickActionsField: string;
    @Input() quickActionCounts: number[];
    @Input() showSelectAllToolbar = true;
    @Input() showQuickActionDefaultColumn = false;
    @Input() justifyContent: string;
    @Output() childComponentClick = new EventEmitter();
    @Output() resetQuickActionCountsEvent = new EventEmitter();
    @Output() gridReady = new EventEmitter();
    @Output() selectionChanged = new EventEmitter();

    // AGGrid Custom Components
    components = {
        agGridActionsCustomColumnComponent: AGGridActionsCustomColumnComponent,
        agGridMaskedValueCellComponent: AgGridMaskedValueCellComponent,
        agGridActionsWithSubmenuColumnComponent: AGGridActionsWithSubmenuColumnComponent,
        agGridDownloadCustomColumnComponent: AGGridDownloadCustomColumnComponent,
        agGridCustomToolPanelComponent: AGGridCustomToolPanelComponent,
        agGridSettingsToolPanelComponent: AGGridSettingsToolPanelComponent,
        agGridCustomListFilterComponent: AgGridCustomListFilterComponent,
        agGridCustomTimeFilterComponent: AgGridCustomTimeFilterComponent,
        sDSSearchRadioButtonComponent: SDSSearchRadioButtonComponent,
        agGridSdsStatusTooltipComponent: AgGridSdsStatusTooltipComponent,
        agGridCustomAutocompleteFilterComponent: AgGridCustomAutocompleteFilterComponent,
        AgGridCustomAutocompleteFloatingFilterComponent: AgGridCustomAutocompleteFloatingFilterComponent,
        AgGridCustomListFloatingFilter: AgGridCustomListFloatingFilter,
        agGridRecurringAuditCellRendererComponent: AgGridRecurringAuditCellRendererComponent,
    };

    style = {
        width: '100%',
        height: '100%',
        flex: '1 1 auto',
    };

    async loadData() {
        if (!this.isGridReady) { return; }
        if (isNullOrUndefined(this.rowData) && this.gridMode === GridMode.ClientSideRowModel) { return; }

        if (this.gridMode === GridMode.ClientSideRowModel) {
            if (this.forceClearRowData) {
                this.gridRowData = [];
                this.gridRowData.push(...this.rowData);
                this.gridApi.applyTransaction({ add: this.gridRowData });
                this.displayedRowCount = this.gridRowData.length;
            } else {
                this.gridRowData.push(...this.rowData);
                this.gridApi.applyTransaction({ add: this.gridRowData });
                this.gridApi.showLoadingOverlay();
                this.displayedRowCount = this.gridRowData.length;
                this.showFiltersClick();

                if (this.initialLoadStateQuery?.predefinedState) {
                    applyPredefinedGridState(this.initialLoadStateQuery.predefinedState, this.gridApi, this.initialLoadStateQueryParams);
                }
                else {
                    await this.restoreAgGridPreviousState();
                }
            }
        }
        if (this.gridMode === GridMode.ServerSideRowModel) {
            this.gridApi.showLoadingOverlay();
            this.isSaveStateAllowed = false;
            if (this.initialLoadStateQuery?.predefinedState) {
                applyPredefinedGridState(this.initialLoadStateQuery.predefinedState, this.gridApi, this.initialLoadStateQueryParams);
            }
            else {
                await this.restoreAgGridPreviousState();
            }

            // This will loop through the filters to try and find any async filters that haven't finished loading yet.
            // If it finds any it will await and continue looping until everything is done loading before we set the datasource to load the data.
            let asyncFiltersDone = false;

            // call refresh on any async set filters so they load before a user opens them. Normally they wouldn't load until the user clicks the filter,
            // but that won't work with our restore previous grid state logic.
            this.gridApi.getColumns().filter(x => x.getColDef()?.filterParams?.isAsync && x.getColDef()?.filter === 'agSetColumnFilter').forEach(e => {
                const filter = this.gridApi.getFilterInstance(e) as any;
                if (filter) {
                    filter.refreshFilterValues();
                }
            });

            while (!asyncFiltersDone) {
                await new Promise(resolve => setTimeout(resolve, 50));
                asyncFiltersDone = true;
                const columns = this.gridApi.getColumns();

                for (let index = 0; index < columns?.length; index++) {
                    const column = columns[index];
                    const filterdef = column.getColDef().filter;

                    if (filterdef && filterdef === 'agGridCustomAutocompleteFilterComponent') {
                        if ((this.gridApi.getFilterInstance(column)as any).loadingData) {
                            asyncFiltersDone = false
                            break;
                        }
                    }
                    else if (filterdef && filterdef === 'agSetColumnFilter' && column.getColDef()?.filterParams?.isAsync && !column.getColDef()?.filterParams?.doneLoading) {
                        asyncFiltersDone = false
                        break;
                    }
                }
            }

           this.gridApi.setGridOption("serverSideDatasource", this.dataSource);
           this.isSaveStateAllowed = true;
           this.saveState()
           this.showFiltersClick();
        }

        if (this.showQuickActions && this.gridStateModel.gridFilterState && this.gridStateModel.gridFilterState[this.quickActionsField]) {
            this.quickActionsColumnFilter = this.gridStateModel.gridFilterState[this.quickActionsField].values;
        }

        setTimeout(() => {
            if (this.gridMode === GridMode.ClientSideRowModel && this.forceClearRowData === false && !this.gridApi.isDestroyed()) {
                this.gridApi.hideOverlay();
            } else if (this.forceClearRowData === true) {
            }
            this.loading = false;
        }, 100);
    }

    setDefaultFilterOptions() {
        // Check for preset filter options; otherwise, assign supported default filters.
        this.columnDefinition.filter(x => x.filter === 'agTextColumnFilter')
            .forEach(filter => {
                if (!filter?.filterParams) {
                    filter['filterParams'] = { suppressAndOrCondition: true,
                                                            defaultOption: 'contains',
                                                            buttons: ['reset'],
                                                            filterOptions: this.defaultTextFilterOptions };
                } else if (!filter?.filterParams?.filterOptions) {
                    filter.filterParams['filterOptions'] = this.defaultTextFilterOptions;
                }
        });
        this.columnDefinition.filter(x => x.filter === 'agNumberColumnFilter' )
            .forEach(filter => {
                if (!filter?.filterParams) {
                    filter['filterParams'] = {  defaultOption: 'equals',
                                                buttons: ['reset'],
                                                suppressAndOrCondition: true,
                                                filterOptions: this.defaultNumberFilterOptions };
                } else if (!filter?.filterParams?.filterOptions) {
                    filter.filterParams['filterOptions'] = this.defaultNumberFilterOptions;
                }
        });
        this.columnDefinition.filter(x => x.filter === 'agDateColumnFilter')
            .forEach(filter => {
                if (!filter?.filterParams) {
                    filter['filterParams'] = {
                        filterOptions: this.defaultDateFilterOptions,
                        minValidYear: '1900',
                        maxValidYear: '9999'
                    };
                }
                else if (!filter?.filterParams?.filterOptions) {
                    filter.filterParams['filterOptions'] = this.defaultDateFilterOptions;
                }
                if(filter?.filterParams){
                    filter['filterParams']['minValidYear'] = '1900';
                    filter['filterParams']['maxValidYear'] = '9999';
                }
                 // stablish the minum width allow so the placeholder of the filter can be seen completely
                 filter.minWidth = this.columnsDateWidth;
                 this.columnsDateIds.push(filter.field);
        });
    }

    // ------------------------------------------------
    // AgGrid - Events
    async onGridReady(params: GridReadyEvent) {
        this.gridApi = params.api;
        // Create some behavior subjects that can be subscribed to so as to be alerted about state changes
        (this.gridApi as any).floatingFilterVisible = new BehaviorSubject(this.floatingFilterVisible);
        (this.gridApi as any).columnFiltersPresent = new BehaviorSubject(this.toolbarActionsEnabled);
        (this.gridApi as any).gridColumnsChanged = new BehaviorSubject(this.gridColumnsChanged);

        this.tryGetDefaultView();

        // Bind Grid Default Events
        defaultGridInit(params);

        // sets the Sidebar Tool Panel
        this.setSidebar();

        if ((this.actionMenuColumn?.length > 0)) { this.addActionMenuColumn(0); }
        if (this.showSelectColumn) { this.addRowSelectColumn(); }

        if (this.gridMode === GridMode.ClientSideRowModel) {
            this.gridApi.showLoadingOverlay();
        }

        this.gridApi.setGridOption("columnDefs", this.columnDefinition);

        this.isGridReady = true;
        this.isOnGridReadyDone = true; // need to have this because isGridReady is deceiving because it seems to change to false in other locations.

        if (this.loading) { this.loadData(); }

        // allow tooltips to work with dynamic content
        (<any>$('.grid-wrapper')).tooltip({
            selector: '[data-bs-toggle="tooltip"]'
        });
        this.gridReady.emit(params);
    }

    onFilterChanged() {

            this.getDisplayedRowCount();
            this.clearCheckboxes();
            this.toolbarActionsEnabled = this.gridApi.isColumnFilterPresent() ? true : false;
            (this.gridApi as any).columnFiltersPresent.next(this.toolbarActionsEnabled);

            this.saveState();
            this.refreshQuickActions();

    }

    quickActionsChanged(selection) {
        if (selection.length) {
            this.gridStateModel.gridFilterState[this.quickActionsField] = { values: selection, filterType: 'set' };
        }
        else {
            this.gridStateModel.gridFilterState[this.quickActionsField] = { values: [], filterType: 'set' };
        }
        this.gridApi.setFilterModel(this.gridStateModel.gridFilterState);
    }

    refreshQuickActions() {
        if (this.showQuickActions && this.gridStateModel.gridFilterState && this.quickActionsComponent) {
            if (this.gridStateModel.gridFilterState[this.quickActionsField]) {
                this.quickActionsColumnFilter = this.gridStateModel.gridFilterState[this.quickActionsField].values;
                setTimeout(() => {
                    this.quickActionsComponent.refreshSelection();
                }, 0);
            }
            else {
                this.resetQuickActions();
            }
        }
    }

    onSortChanged() {
        this.clearCheckboxes();
        this.onGridColumnsChanged();
    }

    onColumnPinned() {
        if (!this.loading) {
            this.onGridColumnsChanged();
        }
    }

    onColumnResized() {
        if (!this.loading) {
            this.onGridColumnsChanged();
        }
    }

    onGridColumnsChanged() {
        if (!this.isGridReady || isNullOrUndefined(this.gridStateModel)) { return; }

        let currentDef: any = this.gridApi.getColumnDefs();

        this.isGridReady = false;
        if (!this.columnDefinition.find(c => c.colId === 'SelectColumn') && this.showSelectColumn == true) {
            this.addRowSelectColumn();
            this.selectALL(false);
            this.gridApi.setGridOption("columnDefs", this.columnDefinition);
        }
        setTimeout(() => {
            this.isGridReady = true;
        }, 100);

        currentDef = currentDef.filter(col => col.hide !== true);

        if (isNullOrUndefined(this.gridStateModel.defaulColumnDefs)) {
            this.gridStateModel.defaulColumnDefs = currentDef;
            return;
        } else {
            let differencesFound = false;
            this.gridStateModel.defaulColumnDefs.forEach((element, index) => {
                if (!isNullOrUndefined(currentDef[index]?.colId)) {
                    if (element.colId !== currentDef[index].colId) {
                        differencesFound = true;
                    }
                    if (element.pinned !== currentDef[index].pinned) {
                        differencesFound = true;
                    }
                    if (element.sort !== currentDef[index].sort) {
                        differencesFound = true;
                    }
                    if (element.width !== currentDef[index].width) {
                        differencesFound = true;
                    }
                    if (element.rowGroup !== currentDef[index].rowGroup) {
                        differencesFound = true;
                    }
                }
            });

            if (this.gridStateModel.defaulColumnDefs.length !== currentDef.length) { differencesFound = true; }

            this.gridColumnsChanged = differencesFound ? differencesFound : false;
            (this.gridApi as any).gridColumnsChanged.next(this.gridColumnsChanged);
            if (differencesFound) { this.saveState(); }
        }
    }

    clearCheckboxes() {
        if (this.getSelectedNodes().length > 0) {
            this.getSelectedNodes().forEach((rowNode) => {
                rowNode.setSelected(false);
            });
        }
    }

    @HostListener('document:click', ['$event'])
    onDocumentClick(event: MouseEvent) {
        const elementToLookFor = document.getElementsByClassName('ag-tool-panel-wrapper')[0];
        const curElement = (<Element>event.target).contains(elementToLookFor) && !(<Element>event.currentTarget).classList?.contains('ag-virtual-list-item');
        // collapse toolPanel based on mouse focus
        if (curElement) {
            setTimeout(() => {

                this.gridApi.closeToolPanel();

            }, 200);
        }
    }

    // collapse toolPanel based on focus of grid
    cellFocused(event) {
        if (event.column !== null) {
            setTimeout(() => {

                this.gridApi.closeToolPanel();

            }, 200);
        }
    }

    focusOut(event) {
        setTimeout(() => {
            if (isNullOrUndefined(event.relatedTarget) && !isNullOrUndefined(this.gridApi) && event.srcElement.className.indexOf('ag-checkbox-input') <= 0) {
                this.gridApi.closeToolPanel();
            }
        }, 200);
    }

    // ------------------------------------------------
    // AgGrid - State Methods
    saveState() {
        if (this.isSaveStateAllowed && !isNullOrUndefined(this.gridStateModel)) {
            this.saveAgGridFilterState();
            this.saveAgGridColumnState();
            this.agGridState.updateAgGridState(this.gridStateModel);
        }
    }

    saveAgGridColumnState() {
        (window as any).colState = this.gridApi.getColumnState();
        // Is to verify that the width for the date columns isn't greater than the allow
        if(!isNullOrUndefined(this.gridStateModel.gridColumnState)){
            this.columnsDateIds.forEach(x  => {
                const dateColumn = this.gridStateModel.gridColumnState.find(y => y.colId === x);
                if(!isNullOrUndefined(dateColumn) && dateColumn.width > this.columnsDateWidth){
                    const idx: number = (window as any).colState.findIndex(y => y.colId === x);
                    (window as any).colState[idx].width = dateColumn.width;
                }
            })
        }
        this.gridStateModel.gridColumnState = (window as any).colState;
    }

    saveAgGridFilterState() {
        this.gridStateModel.gridFilterState = this.gridApi.getFilterModel();
    }

    resetAGGridFilters() {
        this.clearCustomFilters();
        this.resetQuickActions();
        this.gridApi.setFilterModel(null);
        this.autoSizeAll();
        this.saveState();
        this.childComponentClick.emit({ ['ChildComponentAction']: 'Clear Filters' } );
    }

    resetQuickActions() {
        if (this.quickActionsComponent) {
            this.quickActionsComponent.resetSelection();
            this.resetQuickActionCountsEvent.emit();
        }
    }

    resetGridState() {
        const columns = [];
        (window as any).colState = this.gridApi.getColumnState();
        this.columnDefinition.forEach(colDef => {
            const prop = isNullOrUndefined(colDef.field) ? 'colId' : 'field';
            let column = (window as any).colState.find(x => x.colId == colDef[prop]);
            const col = this.gridStateModel.defaulColumnDefs.find(x => x.colId == colDef[prop]);
            if(isNullOrUndefined(col)) {
                column.checkboxSelection = false;
                column.hide = true;
            }
            else {
                column = col;
                column.hide = false;
            }
            columns.push(column);
        });
        this.gridApi.applyColumnState({
            state: columns,
            applyOrder: true,
        });
        this.resetAGGridFilters();
    }

    async restoreAgGridPreviousState() {
        if (!isNullOrUndefined(this.gridStateModel) && !this.initialLoadStateQuery?.predefinedState) {
            this.gridStateModel = this.agGridState.restoreAgGridState(this.gridStateModel.gridKey);
            this.gridApi.setFilterModel(this.gridStateModel.gridFilterState);

            if (!isNullOrUndefined(this.gridStateModel.gridColumnState) && !isNullOrUndefined(this.gridStateModel.gridFilterState)) {
                if (!isNullOrUndefined(this.gridStateModel.gridColumnState)) {
                    (window as any).colState = this.gridStateModel.gridColumnState;
                    this.gridApi.applyColumnState({
                        state: (window as any).colState,
                        applyOrder: true,
                    });
                    this.columnDefinition.forEach(colDef =>{
                        const col = this.gridStateModel.gridColumnState.find(y => y.colId === colDef.field);
                        if(!isNullOrUndefined(col)){
                            Object.getOwnPropertyNames(col).forEach(prop => {
                                colDef[prop] = col.prop
                            })
                        }
                    });
                }
            } else if (this.getDefaultViewPromise && !this.hideSettingsToolPanel && this.gridId) {
                await this.getDefaultViewPromise;
                if (this.defaultView && this.defaultView.gridViewState.length > 0) {
                    applyGridViewToGrid(this.defaultView, this.gridApi);
                }
                this.autoSizeAll();
            }
        }
    }

    // ------------------------------------------------

    // Sidebar
    setSidebar() {
        if (this.showSideBar) {

            if (!isNullOrUndefined(this.overwriteSidebar)) { this.sideBar = this.overwriteSidebar; return; }

            this.sideBar = {
                toolPanels: [
                    {
                        id: 'columns',
                        labelDefault: 'Columns',
                        labelKey: 'columns',
                        iconKey: 'columns',
                        toolPanel: 'agColumnsToolPanel',
                        toolPanelParams: {
                            supressPivots: true,
                            suppressPivotMode: true,
                            suppressRowGroups: !this.groupingEnabled,
                            suppressValues: !this.groupingEnabled,
                        }
                    },
                ],
            };

            if (!this.noFiltersOnSideBar) {
                this.sideBar.toolPanels.push({
                    id: 'filters',
                    labelDefault: 'Filters',
                    labelKey: 'filters',
                    iconKey: 'filter',
                    toolPanel: 'agFiltersToolPanel'
                });
            }

            if (this.customToolPanelParams) {
                this.sideBar.toolPanels.push({
                    id: 'customToolPanel',
                    labelDefault: 'Actions',
                    labelKey: 'customToolPanel',
                    iconKey: 'chart',
                    toolPanel: 'agGridCustomToolPanelComponent',
                    toolPanelParams: {
                        gridType: this.customToolPanelParams.gridType,
                        columnsToIgnore: this.customToolPanelParams.columnsToIgnore,
                        onDelete: this.handleSelectedNodesToolPanelClick.bind(this, 'CustomToolPanelDelete'),
                        onPrint301: this.handleSelectedNodesToolPanelClick.bind(this, 'CustomToolPanelPrintForm301'),
                        onMakeActive: this.handleSelectedNodesToolPanelClick.bind(this, 'CustomToolPanelMakeActive'),
                        showMakeActive: this.customToolPanelParams.showMakeActive,
                        onMakeInactive: this.handleSelectedNodesToolPanelClick.bind(this, 'CustomToolPanelMakeInactive'),
                        showMakeInactive: this.customToolPanelParams.showMakeInactive,
                        onResetLearnerPassword: this.handleSelectedNodesToolPanelClick.bind(this, 'CustomToolPanelResetLearnerPassword'),
                        onExportAll: this.onExportAll.bind(this),
                        rowModelType: this.gridMode,
                        additionalActions: this.additionalCustomSidePanelActions,
                        action: this.handleGridActionComponentEvent.bind(this),
                        userHasEditPermissions: this.customToolPanelParams.userHasEditPermissions,
                        hideDelete: this.customToolPanelParams.hideDelete,
                        showFindAndMonitorSDSs: this.customToolPanelParams.showFindAndMonitorSDSs,
                        onFindAndMonitorSDSs: this.handleSelectedNodesToolPanelClick.bind(this, 'CustomToolPanelFindAndMonitorSDSs'),
                        showCompanyUsage: this.customToolPanelParams.showCompanyUsage,
                        onCompanyUsage: this.handleSelectedNodesToolPanelClick.bind(this, 'CustomToolPanelCompanyUsage'),
                        hideImportExport: this.customToolPanelParams.hideImportExport,
                        showMassDataGroupUpdate: this.customToolPanelParams.showMassDataGroupUpdate,
                        onMassDataGroupUpdate: this.handleSelectedNodesToolPanelClick.bind(this, 'CustomToolPanelMassDataGroupUpdate'),
                    }
                });
            }

            if (!this.hideSettingsToolPanel && (this.gridId || this.hideSaveViews)) {
                this.sideBar.toolPanels.unshift({
                    id: 'settingsToolPanel',
                    labelDefault: '',
                    labelKey: 'settingsToolPanel',
                    iconKey: 'customGear',
                    toolPanel: 'agGridSettingsToolPanelComponent',
                    toolPanelParams: {
                        gridId: this.gridId,
                        rowModelType: this.gridMode,
                        quickActionsColumn: this.quickActionsField,
                        onShowFilters: this.showFiltersClick.bind(this),
                        onResetFilters: this.resetAGGridFilters.bind(this),
                        onResetGridState: this.resetGridState.bind(this),
                        hideSaveViews: this.hideSaveViews
                    }
                });
            }
        } else {
            this.sideBar = 'false';
        }
    }

    // Context Menu
    getContextMenuItems = (params) => {
        if (this.customContextMenuItems) {
            return this.customContextMenuItems;
        } else {
            const contextMenuItems = [
                'copy',
                'copyWithHeaders',
                'paste'
            ];

            if (this.enableCharts) {
                contextMenuItems.push('chartRange');
            }

            return contextMenuItems;
        }
    }

    // Action Menu Column
    addActionMenuColumn(index: number) {
        this.columnDefinition.splice(index, 0, {
            headerName: 'Menu',
            width: 90,
            minWidth: 90,
            maxWidth: 90,
            filter: false,
            suppressMovable: true,
            colId: 'ActionsColumn',
            cellRenderer: this.actionCellRenderer,
            cellClass: 'actions-button-cell bold',
            cellRendererParams: {
                customColumnParams: this.actionMenuColumn,
                action: this.gridActionComponentEvent,
            },
            suppressColumnsToolPanel: true,
            suppressFiltersToolPanel: true,
            lockPosition: !this.groupingEnabled,
            suppressMenu: true,
            sortable: false,
        });
    }

    gridActionComponentEvent(params) {
        params.context.componentParent.actionsChildComponent = params;
    }

    handleSelectedNodesToolPanelClick(actionName: string){
        const selectedNodes = this.getSelectedNodes();
        selectedNodes['ChildComponentAction'] = actionName;
        this.childComponentClick.emit(selectedNodes);
    }

    getSelectedNodes() {
        const rowData = [];
        if (this.gridMode === GridMode.ClientSideRowModel) {
            this.gridApi.forEachNodeAfterFilter(node => {
                rowData.push(node);
            });
            return rowData.filter(x => x.isSelected());
        }
        else {
            return this.gridApi.getSelectedNodes();
        }
    }

    handleGridActionComponentEvent(params) {
        if (!isNullOrUndefined(params.ChildComponentAction)) {
            this.childComponentClick.emit(params);
        }
    }

    addRowSelectColumn() {
        if (!this.columnDefinition.some(x => x.colId === 'SelectColumn')) {
            this.columnDefinition.unshift({
                headerName: '',
                colId: 'SelectColumn',
                checkboxSelection: true,
                suppressMovable: true,
                headerCheckboxSelection: this.gridMode === GridMode.ClientSideRowModel,
                headerCheckboxSelectionFilteredOnly: this.gridMode === GridMode.ClientSideRowModel,
                width: 40,
                minWidth: 40,
                maxWidth: 40,
                suppressColumnsToolPanel: true,
                suppressFiltersToolPanel: true,
                filter: false,
                lockPosition: !this.groupingEnabled,
                suppressMenu: true,
                cellRendererParams: { checkbox: true },
            });
        }
    }

    autoSizeAll() {
        if (this.autosizeColumns) {
            const allColumnIds = [];
            this.gridApi.getColumns().forEach((column) => {
                allColumnIds.push(column.getId());
            });
            this.gridApi.autoSizeColumns(allColumnIds, false);
        }

        if(!isNullOrUndefined(this.gridStateModel)){
            // Set the default widths to be the same as the resized column widths so the grid doesn't always think a change has been made that can be reset.
            let currentDef: any = this.gridApi.getColumnDefs();
            currentDef = currentDef.filter(col => col.hide !== true);
            if (isNullOrUndefined(this.gridStateModel.defaulColumnDefs)) {
                this.gridStateModel.defaulColumnDefs = currentDef;
            }

            this.gridStateModel.defaulColumnDefs?.forEach((element, index) => {
                if (!isNullOrUndefined(currentDef[index]?.colId)) {
                    element.width = currentDef[index].width
                }
            });
        }
    }

    clearCustomFilters() {
        // This list must contain the name of the custom filters implemented for the grid intance
        this.customFilterList.forEach(element => {
            try {
                const agGridFilter = (this.gridApi.getFilterInstance(element) as AgGridCustomListFilterComponent);
                agGridFilter.clearFilter();
            } catch (error) {
                console.error('An error occurred cleaning the filter ' + element, error);
            }
        });
    }

    getDisplayedRowCount() {
        if (!isNullOrUndefined(this.gridApi)) {
            this.displayedRowCount = this.gridApi.getDisplayedRowCount();
        }
    }

    // ------------------------------------------------
    // ag-grid-custom-toolbar - Actions
    onToolbarActionClick(event) {
        switch (event) {
            case 'Reset': {
                this.resetGridState();
                break;
            }
            case 'Clear': {
                this.resetAGGridFilters();
                break;
            }
            case 'ShowFilters': {
                this.showFiltersClick();
                break;
            }
            case 'selectAll': {
                this.selectALL();
                break;
            }
            case 'deselectAll': {
                this.selectALL(false);
                break;
            }
        }
    }

    processCellForClipboard(params) {
        return formatColumnsForExportAndClipboard(params);
    }

    onExportAll(event) {
        this.exportAll = true;
        this.sideBar['ChildComponentAction'] = 'exportAll';
        this.childComponentClick.emit(this.sideBar);
    }

    showFiltersClick() {
        this.floatingFilterVisible = !this.floatingFilterVisible;

        this.restoreAgGridPreviousState();
        const columnDefinition = this.gridApi.getColumnDefs();

        columnDefinition.map(col => {
            col['floatingFilter'] = this.floatingFilterVisible;
        });

        this.gridApi.setGridOption("columnDefs", columnDefinition);

        (this.gridApi as any).floatingFilterVisible.next(this.floatingFilterVisible);
    }

    onSelectionChanged(event: SelectionChangedEvent) {
        this.selectedRowsCount = this.gridApi.getSelectedRows().length;
        this.selectionChanged.emit(event);
    }

    selectALL(select = true) {
        this.gridApi.forEachNode(function (node) {
            if (select && node.selectable) {
                node.setSelected(true) ;
            } else {
                node.setSelected(false);
            }
          });
    }

    tryGetDefaultView() {
        if (!this.hideSettingsToolPanel && this.gridId && !isNullOrUndefined(this.gridStateModel) && !this.initialLoadStateQuery?.predefinedState) {
            this.gridStateModel = this.agGridState.restoreAgGridState(this.gridStateModel.gridKey);
            this.gridApi.setFilterModel(this.gridStateModel.gridFilterState);

            // only need to call the promise if there isn't a grid state stored in session
            if (isNullOrUndefined(this.gridStateModel.gridColumnState) || isNullOrUndefined(this.gridStateModel.gridFilterState)) {
                this.getDefaultViewPromise = new Promise((resolve, reject) => {
                    try {
                        ApiFactory.retrieveEntity(ApiEntityTypesEnum.GridView)
                        .addRouteHint("GetUserDefaultForGrid")
                        .addDataEntry("gridId", this.gridId)
                        .addSuccessHandler((data: GridView) => {
                            this.defaultView = data;
                            resolve(data);
                        })
                        .addErrorHandler((error) => {
                            reject(error);
                        })
                        .buildAndSend();

                    } catch (error) {
                        reject();
                    }
                });
            }
        }
    }

    fixMargin(event) {
        const value = (event as string);
        this.marginBottom = (60 + +value.substring(0, value.length - 2)) + 'px';
    }
    refreshCustomActions(){
        this.gridApi.redrawRows();
    }
}
